import { GetterTree, MutationTree, ActionTree } from "vuex";
import { WishlistItems } from "~~/lib/types/Salesforce/Wishlist";
import { getItemFromLocalStorage } from "~~/lib/utils/manageLocalStorageItems";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Store, logTags.Feature.Wishlist],
});

export const state = () => ({
  updated: false,
  count: 0,
  items: {},
});

export type State = {
  updated: boolean;
  count: number;
  items: WishlistItems;
};

export const getters: GetterTree<State, State> = {
  getWishlistCount(state: State) {
    return state.count;
  },
  getWishlistItems(state: State) {
    return state.items;
  },
  getStatus(state: State) {
    return state.updated;
  },
};

export const mutations: MutationTree<State> = {
  SET_WISHLIST_COUNT(state: State, wishlistCount: number) {
    state.count = wishlistCount;
  },
  SET_WISHLIST_ITEMS(state: State, wishlist: WishlistItems) {
    state.items = wishlist;
  },
  SET_STATUS(state: State, status: boolean) {
    state.updated = status;
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  setWishlist({ commit }, wishlistItems) {
    commit("SET_WISHLIST_COUNT", Object.keys(wishlistItems || {}).length ?? 0);
    commit("SET_WISHLIST_ITEMS", wishlistItems || {});
    commit("SET_STATUS", true);

    window.localStorage.setItem(
      "wishlist",
      JSON.stringify({ items: wishlistItems })
    );
  },
  initialize({ commit }) {
    try {
      const wishlist = JSON.parse(getItemFromLocalStorage("wishlist") || "{}");
      commit(
        "SET_WISHLIST_COUNT",
        Object.keys(wishlist?.items || {}).length ?? 0
      );
      commit("SET_WISHLIST_ITEMS", wishlist?.items || {});
      commit("SET_STATUS", true);
    } catch (error) {
      logger.error("Error getting wishlist from localstorage", error);
    }
  },
};

export default { state, mutations, getters, actions };
