import { GetterTree, MutationTree, ActionTree } from "vuex";
import { HeaderData } from "./types";
import { NotificationProps } from "~~/components/Notification/Props";
import { getHeaderMenu } from "~~/components/Header/headerMenu";
import Menu from "~~/lib/types/Menu";
import { InitPageParams } from "~~/lib/utils/initPage";
import { contextualFetch } from "~~/lib/utils/contextualFetch";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Feature.Header, logTags.Layer.Store],
});

export const state = (): {
  menuItems: Menu[];
  notification?: NotificationProps;
} => ({
  notification: undefined,
  menuItems: [],
});

export type State = ReturnType<typeof state>;

export const getters: GetterTree<State, State> = {
  getNotification(state: State) {
    return state.notification;
  },
  getMenuitems(state: State) {
    return state.menuItems;
  },
};

export const mutations: MutationTree<State> = {
  SET_NOTIFICATION(state: State, notification: NotificationProps) {
    state.notification = notification;
  },
  SET_STATIC_MENU_ITEMS(state: State, menuItems: Menu[]) {
    state.menuItems = menuItems;
  },
  UPDATE_MENU_ITEMS_WITH_DEALS_MENU(state: State, dealsMenu: Menu[]) {
    if (state.menuItems && state.menuItems[3].children) {
      state.menuItems[3].children = dealsMenu;
    }
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  async getNotificationAndDealsMenuItems({ commit }, params: InitPageParams) {
    try {
      const { dealsMenu: contentstackDealsMenu, notification }: HeaderData =
        await contextualFetch("/api/nuxt/header", {
          params,
        });

      const staticDealsMenu = [...getHeaderMenu()][3]?.children as Menu[];

      const dealsMenu = [...(contentstackDealsMenu || []), ...staticDealsMenu];
      commit("UPDATE_MENU_ITEMS_WITH_DEALS_MENU", dealsMenu);
      commit("SET_NOTIFICATION", notification);
    } catch (error) {
      commit("SET_STATIC_MENU_ITEMS", getHeaderMenu());
      logger.error("Error in setting Notification and Deals Menu Items", {
        functionName: "getNotificationAndDealsMenuItems",
        params,
        error,
      });
    }
  },
  async getNotificationAndMenuItems(
    { commit, dispatch },
    params: InitPageParams
  ) {
    commit("SET_STATIC_MENU_ITEMS", getHeaderMenu());
    await dispatch("getNotificationAndDealsMenuItems", params);
  },
};

export default { state, mutations, getters, actions };
