import { ActionTree, GetterTree, MutationTree } from "vuex";

export const state = () => ({
  algoliaPublicSearchKey: "",
});

export type State = ReturnType<typeof state>;

export const getters: GetterTree<State, State> = {
  algoliaPublicSearchKey(state: State) {
    return state.algoliaPublicSearchKey;
  },
};

export const mutations: MutationTree<State> = {
  SET_ALGOLIA_PUBLIC_SEARCH_KEY(state: State, key: string) {
    state.algoliaPublicSearchKey = key;
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  async getAlgoliaPublicSearchKey({ commit }) {
    const { algoliaPublicSearchKey }: State = await $fetch<State>(
      `/api/nuxt/global/algolia`
    );

    if (algoliaPublicSearchKey) {
      commit("SET_ALGOLIA_PUBLIC_SEARCH_KEY", algoliaPublicSearchKey);
    }
  },
};

export default { state, mutations, getters, actions };
