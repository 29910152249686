import { createLink } from "~~/lib/utils/link/createLink";
import { getIsAllowedAsNuxtLinks } from "~~/lib/utils/link/getIsAllowedAsNuxtLinks";
export const plugin = (context, inject) => {
  const getAgentLink = createLink(context.i18n.localeProperties.code, context.params.currencyCode);
  const getPublicLink = createLink(context.i18n.localeProperties.code);
  inject("link", {
    create: url => {
      if (context.$agents.getIsAgentLoggedIn()) {
        return getAgentLink(url);
      }
      return getPublicLink(url);
    },
    getIsAllowedAsNuxtLinks
  });
};
export default plugin;