import { ActionTree, GetterTree, MutationTree } from "vuex";

export const state = () => ({
  componentUpdatedAt: undefined,
});

export type State = {
  componentUpdatedAt?: number;
};

export const getters: GetterTree<State, State> = {
  componentUpdatedAt(state: State) {
    return state.componentUpdatedAt;
  },
};

export const mutations: MutationTree<State> = {
  COMPONENT_UPDATE(state: State, value: number) {
    state.componentUpdatedAt = value;
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  componentUpdate({ commit }) {
    commit("COMPONENT_UPDATE", Date.now());
  },
};

export default { state, mutations, getters, actions };
