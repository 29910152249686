import { GetterTree, MutationTree, ActionTree } from "vuex";

export const state = (): {
  liveChatStatus: boolean;
} => ({
  liveChatStatus: false,
});

export type State = ReturnType<typeof state>;

export const getters: GetterTree<State, State> = {
  getLiveChatStatus(state: State) {
    return state.liveChatStatus;
  },
};

export const mutations: MutationTree<State> = {
  SET_LIVE_CHAT_STATUS(state: State, liveChatStatus: boolean) {
    state.liveChatStatus = liveChatStatus;
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  setLiveChatStatus({ commit }, status: string) {
    commit("SET_LIVE_CHAT_STATUS", status);
  },
};

export default { state, mutations, getters, actions };
