import Vue from "vue";
import dayjs from "dayjs";

export default () => {
  Vue.filter("formatDate", function (date: Date, format = "DD MMM YYYY") {
    if (!date) return "";
    // dont know if locale needed here
    return dayjs(date).format(format);
  });
};
