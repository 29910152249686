import CookieKey from "../lib/types/CookieKey";
export const getIsAgentLoggedIn = cookie => {
  const isAgentLoggedInRegexSet = `${CookieKey.Agent}|${CookieKey.EnduranceBuyingAgent}|${CookieKey.EnduranceAgent}`;
  const isAgentLoggedInRegex = new RegExp(`${isAgentLoggedInRegexSet}=true`, "g");
  if (cookie !== null && cookie !== void 0 && cookie.match(isAgentLoggedInRegex)) {
    return true;
  }
  return false;
};
export const plugin = (context, inject) => {
  let agents;
  if (process.client) {
    agents = {
      getIsAgentLoggedIn: () => getIsAgentLoggedIn(document.cookie)
    };
  } else {
    agents = {
      getIsAgentLoggedIn: () => getIsAgentLoggedIn(context.ssrContext.req.headers.cookie)
    };
  }
  inject("agents", agents);
};
export default plugin;