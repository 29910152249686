import { GetterTree, ActionTree, MutationTree } from "vuex";
import Cookies from "js-cookie";
import isoCountries from "i18n-iso-countries/langs/en.json";
import type { LocaleObject } from "@nuxtjs/i18n";
import cookieKey from "~~/lib/types/CookieKey";
import { getLocaleByCountry } from "~~/lib/utils/locale/getLocaleByCountry";

export const state = () => ({
  preferredRegion: "",
  originCountry: "",
  originCountryName: "",
  firstName: "",
});

export type State = ReturnType<typeof state>;

export const getters: GetterTree<State, State> = {
  originCountry() {
    return Cookies.get(cookieKey.OriginCountry) ?? "";
  },
  originCountryName(): string | undefined {
    const country = Cookies.get(cookieKey.OriginCountry);
    const countryName = country
      ? (isoCountries.countries as Record<string, string | string[]>)?.[
          country.toUpperCase()
        ]
      : undefined;

    return Array.isArray(countryName) ? countryName[0] : countryName;
  },
  preferredRegion() {
    return Cookies.get(cookieKey.PreferredRegion) ?? "";
  },
  firstName() {
    return Cookies.get(cookieKey.FirstName) ?? "";
  },
};

export const mutations: MutationTree<State> = {
  SET_PREFERRED_REGION: (state, locale: LocaleObject) => {
    Cookies.set(
      cookieKey.PreferredRegion,
      locale.enduranceRegion ?? locale.code
    );
  },
  SET_ORIGIN_COUNTRY: (state, originCountry: string) => {
    Cookies.set(cookieKey.OriginCountry, originCountry);
  },
  SET_FIRST_NAME: (state, firstName: string) => {
    Cookies.set(cookieKey.FirstName, firstName);
  },
};

export const actions: ActionTree<State, {}> = {
  preferCurrentRegion({ commit }) {
    commit("SET_PREFERRED_REGION", this.$i18n.localeProperties);
  },

  goToRegion({ getters }) {
    const region = getLocaleByCountry(getters.originCountry);

    window.location.href = this.switchLocalePath(region.code);
  },
  setPreferredRegion({ commit }, locale: LocaleObject) {
    commit("SET_PREFERRED_REGION", locale);
  },
  setFirstName({ commit }, firstName: string) {
    commit("SET_FIRST_NAME", firstName);
  },
};

export default { state, mutations, actions, getters };
