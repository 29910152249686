import { ActionTree, GetterTree, MutationTree } from "vuex";

export const state = () => ({
  pageType: undefined,
});

export type State = {
  pageType?: string;
};

export const getters: GetterTree<State, State> = {
  getPageType(state: State) {
    return state.pageType;
  },
};

export const mutations: MutationTree<State> = {
  PAGE_TYPE(state: State, value: string) {
    state.pageType = value;
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  setPageType({ commit }, pageType: string) {
    commit("PAGE_TYPE", pageType);
  },
};

export default { state, mutations, getters, actions };
