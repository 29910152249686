import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
const logger = loggerFactory({
  tags: [logTags.Layer.Plugin]
});
export const plugin = (_ref, inject) => {
  let {
    $config,
    route
  } = _ref;
  let isLivePreviewInitialized = false;
  inject("initLivePreview", () => {
    var _$config$public;
    if (!($config !== null && $config !== void 0 && (_$config$public = $config.public) !== null && _$config$public !== void 0 && _$config$public.contentstackEnableLivePreview)) return;
    if (process.client && !isLivePreviewInitialized && route.fullPath.match(/\/preview\//)) {
      import("@contentstack/live-preview-utils").then(ContentstackLivePreview => {
        var _init;
        (_init = ContentstackLivePreview.init({
          enable: true,
          ssr: true,
          stackDetails: {
            apiKey: $config.public.contentstackApiKey
          }
        })) === null || _init === void 0 ? void 0 : _init.catch(err => logger.warn("Cannot initialize LivePreview", {
          error: err
        }));
        isLivePreviewInitialized = true;
      }).catch(err => logger.warn("Cannot import LivePreview", {
        error: err
      }));
    }
  });
};
export default plugin;