export const plugin = (_, inject) => {
  inject("utag", {
    link: utag => {
      var _window, _window$utag;
      // @ts-ignore
      if ((_window = window) !== null && _window !== void 0 && (_window$utag = _window.utag) !== null && _window$utag !== void 0 && _window$utag.link) {
        // @ts-ignore
        window.utag.link(utag, null, [384]);
      }
    }
  });
};
export default plugin;