import { GetterTree, ActionTree, MutationTree } from "vuex";
import { SalesRegion } from "~/lib/types/Contentstack/ContentTypes/SalesRegion";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Feature.Header, logTags.Layer.Store],
});

export const state = () => ({
  directPhoneNumber: "",
  regionCode: "",
  currencyCode: "",
  gsaLogo: [
    {
      alt_text: "",
      picture: {
        url: "",
      },
    },
  ],
});

export type State = ReturnType<typeof state>;

export const getters: GetterTree<State, State> = {
  directPhoneNumber(state: State) {
    return state.directPhoneNumber;
  },
  regionCode(state: State) {
    return state.regionCode;
  },
  currencyCode(state: State) {
    return state.currencyCode;
  },
  gsaLogoUrl(state: State) {
    return state.gsaLogo[0]?.picture?.url || "";
  },
  gsaLogoAltText(state: State) {
    return state.gsaLogo[0]?.alt_text || "";
  },
};

export const mutations: MutationTree<State> = {
  SET_SALES_REGION: (state: State, salesRegion: SalesRegion) => {
    state.regionCode = salesRegion.region_code || "";
    state.currencyCode = salesRegion?.currency_code || "";
    state.directPhoneNumber = salesRegion?.direct_phone_number || "";
    state.gsaLogo =
      salesRegion.gsa_logo && salesRegion.gsa_logo?.at(0)
        ? [
            {
              alt_text: salesRegion.gsa_logo[0].alt_text || "",
              picture: {
                url: salesRegion?.gsa_logo[0].picture?.url || "",
              },
            },
          ]
        : [];
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  async getSalesRegion({ commit }, locale: string) {
    try {
      const salesRegion = await $fetch("/api/nuxt/sales-region", {
        params: { locale },
      });
      commit("SET_SALES_REGION", salesRegion);
    } catch (error) {
      logger.error("Exception in setting SalesRegion", {
        localeCode: locale,
        error,
      });
    }
  },
};

export default { state, mutations, actions, getters };
