import Vue from "vue";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
const logger = loggerFactory({
  tags: [logTags.Function.ErrorBoundary, logTags.Layer.Component]
});
export const handleError = err => {
  logger.error("Error captured in handleError", err);
  if (!process.client) {
    return true;
  }
  if (!window || !window.newrelic || !window.newrelic.noticeError) {
    return false;
  }
  window.newrelic.noticeError(err);
  return true;
};
export const plugin = (_, inject) => {
  if (process.client && window) {
    window.onunhandledrejection = event => {
      var _event$reason;
      const rejectionReason = (_event$reason = event === null || event === void 0 ? void 0 : event.reason) !== null && _event$reason !== void 0 ? _event$reason : "Unhandled promise rejection without a reason";
      handleError(new Error(rejectionReason));
    };
  }
  Vue.config.errorHandler = err => {
    handleError(err);
  };
  inject("handleError", handleError);
};
export default plugin;